// extracted by mini-css-extract-plugin
export var challengeSection = "secureProcess-module--challengeSection--0de2e";
export var containBody = "secureProcess-module--containBody--d33fc";
export var containCars = "secureProcess-module--containCars--c0f8c";
export var containProject = "secureProcess-module--containProject--ce6c8";
export var containTitle = "secureProcess-module--containTitle--26be8";
export var description = "secureProcess-module--description--d6619";
export var detalle = "secureProcess-module--detalle--b169e";
export var number = "secureProcess-module--number--cc688";
export var step = "secureProcess-module--step--d44e3";
export var title = "secureProcess-module--title--b665d";