import React from "react";
import Layout from "../../components/UI/layout";
import Header from "../../components/UI/Header/header";
import SEO from "../../components/UI/seo";
import Help from "../../components/challenges/challengeTwo/Help/help";
import SecureProcessSection from "../../components/challenges/challengeTwo/secureProcess/secureProcess";
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next";



const ChallengeTwo = () => {
  const {t} = useTranslation();
  return(
    <Layout>
      <SEO title="Codeicus | ChallengesTwo" />
      <Header
        title="Diseñar procesos de negocio ágiles y seguros"
        description={["Por un lado los nuestros clientes son cada vez más tecnológicos y necesitamos agilizar al máximo cualquiera de nuestros procesos.<br></br> Por el otro, el fraude tecnológico aumenta y necesitamos garantizar la tranquilidad en nuestros procesos para brindar una experiencia segura.<br></br> Desde Codeicus, con experiencia en este tema, recomendamos la integración con procesos seguros que nos permitan agilizar validaciones."]}
      />
      <SecureProcessSection/>
      <Help t={t}/>
    </Layout>
  )
}
  
  export default ChallengeTwo

  export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;