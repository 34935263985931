// extracted by mini-css-extract-plugin
export var bodyHelp = "help-module--bodyHelp--bd3bf";
export var btn = "help-module--btn--72456";
export var btnLottie = "help-module--btnLottie--732c3";
export var contenerHelp = "help-module--contenerHelp--6fd48";
export var detail = "help-module--detail--df103";
export var error = "help-module--error--157fc";
export var fromCenter = "help-module--fromCenter--b08ad";
export var fromContacto = "help-module--fromContacto--5adea";
export var fromLeft = "help-module--fromLeft--4b150";
export var textArea = "help-module--textArea--56118";
export var textBox = "help-module--textBox--8c841";
export var title = "help-module--title--a41ba";