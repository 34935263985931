import React from 'react';
import * as styles from './secureProcess.module.scss';
import { useTranslation } from "gatsby-plugin-react-i18next"



const SecureProcessSection = () => {


    const {t} = useTranslation();

	return (
		<section className={styles.challengeSection} id='challengeSection'>
			<div className={styles.containProject}>
                <div className={styles.containTitle}    
                    data-sal-delay='400'
                    data-sal='slide-up'
                    data-sal-duration='400'
                    data-sal-easing='ease-out-back'>
                    <h3>{"Algunos de los más recomendados son"}</h3>
                    
                </div>
                <div className={styles.containBody}>
                    <div className={styles.containBody}>                   
                    <div className={styles.step}
                         data-sal-delay='400'
                        data-sal='slide-right'
                        data-sal-duration='500'
                        data-sal-easing='ease-out-back'>
                        <div className={styles.detalle}>
                            <div className={styles.number}>
                                <p>1</p>
                            </div>
                            <div className={styles.title}>     
                                <h3>{"SID - Sistema de Identidad Digital: es una plataforma desarrollada por el Estado que permite validar la identidad a distancia y en tiempo real con el Renaper mediante factores de autenticación biométrica."}</h3> 
                            </div>
                        </div>
                    </div>
                    <div className={styles.step}                        
                        data-sal-delay='400'
                        data-sal='slide-right'
                        data-sal-duration='500'
                        data-sal-easing='ease-out-back'>
                        <div className={styles.detalle}>
                            <div className={styles.number}>
                                <p>2</p>
                            </div>
                            <div className={styles.title}>     
                                <h3>{"Firma digital: Una solución tecnológica, segura y confiable que permite firmar digitalmente documentos electrónicos con la misma validez jurídica que la firma holográfica. "}</h3> 
                            </div>
                        </div>
                    </div>
                    <div className={styles.step}                        
                        data-sal-delay='400'
                        data-sal='slide-right'
                        data-sal-duration='500'
                        data-sal-easing='ease-out-back'>
                        <div className={styles.detalle}>
                            <div className={styles.number}>
                                <p>3</p>
                            </div>
                            <div className={styles.title}>     
                                <h3>{"Amazon Rekognition: Una API de Amazon que realiza análisis de imagen y video para garantizar la seguridad de la información recibida. "}</h3> 
                            </div>
                        </div>
                    </div>       
                </div>
                </div>

			</div>
		</section>
	);
};
export default SecureProcessSection ;